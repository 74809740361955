import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import Navbar from "./common/Navbar";
import { Link } from "react-router-dom";
import logo from "../assets/cropped-Guangda_logo_footer.png";

const Footer = () => {
  return (
    <footer className="bg-gray-800 py-6">
      <div className="container mx-auto py-4 flex flex-col md:flex-row text-white justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="logo" className="h-12 w-auto mr-2" />
          </Link>
        </div>
        <Navbar color="text-white" />
        <div className="flex flex-col space-y-2 w-1/4 text-left">
          <div className="flex items-center space-x-2">
            <FaPhoneAlt />
            <span>
              <Link to="tel:+6583559501">+65-83559501</Link>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <FaEnvelope />
            <span>
              <Link to="mailto:sales@guangda.sg">sales@guangda.sg</Link>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <FaMapMarkerAlt />
            <span>12, Hoy Fatt Road, Bryton House, #06-01 room 13, 159506</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
