import HeroImage from "../../images/back-lamp.png";


const AboutUsPage = () => {
    const items = [
        {
          text: 'The best design of our products can be achieved through careful consideration of user needs, market trends, and functionality.',
          icon: 'https://guangda.sg/wordpress/wp-content/uploads/2023/09/1.svg',
          alt: 'Иконка 1',
        },
        {
          text: 'We use high-quality raw materials and stay updated with advancements in materials and technology to create innovative and durable products.',
          icon: 'https://guangda.sg/wordpress/wp-content/uploads/2023/09/2.svg',
          alt: 'Иконка 2',
        },
        {
          text: 'We take pride in our efficient order processing system, ensuring that orders for our products are processed promptly. Our dedicated team works diligently for you.',
          icon: 'https://guangda.sg/wordpress/wp-content/uploads/2023/09/3.svg',
          alt: 'Иконка 3',
        },
      ];
    
    return (
      <>
        <section className="py-10 md:py-16">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col-reverse md:flex-row md:justify-between bg-[#3C489E] rounded-lg p-12 ">
          {/* Текстовый контент */}
          <div className="md:w-1/2 mt-8 md:mt-0">
            <h2 className="text-[40px] text-white mb-8">About company</h2>
            <div className="text-white text-[15px] leading-[25px]">
                <p>
                    GUANGDA SHANGWU CO., PTE. LTD is multinational engineering and technology company that does technical design and quality control for well-known brands PECAHTA and HUTER.
                    Our brand, PECAHTA (Resanta) was introduced in 1990. Our high-quality DIY power tools make home decoration easy and enjoyable.
                </p>
      
                <p>
                    Power tools, power tool accessories, and house heating technology represent our power tools division. In addition to power tools such as hammer drills, cordless screwdrivers, and jigsaws, its products include gardening equipment such as lawnmowers, hedge trimmers, high-pressure cleaners, and various garden-types pumps, tillers, and snow blow machines.
                    Huter has been one of the World’s biggest-selling chain saws and trimmers since 2015.
                </p>
            </div>
          </div>
          {/* Изображение */}
          <div className="md:w-1/2 flex justify-center overflow-hidden">
            <img
              className="w-full h-auto object-cover md:h-[500px] md:object-contain transform scale-[1.4] md:scale-100 -ml-5 md:ml-0"
              src="https://guangda.sg/wordpress/wp-content/uploads/2023/09/LED-lamp-Eurolux-LL-E-A80-25W-230-4K-E27-1-1.png"
              alt="О нас"
            />
          </div>
        </div>
      </div>
    </section>

    <section className="py-10 md:py-16">
      <div className="max-w-6xl mx-auto">
        <ul className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16">
          {items.map((item, index) => (
            <li key={index} className="relative text-white bg-[#F15722] rounded-lg p-8 pt-[104px] flex items-end">
              <div className="absolute top-[30px] left-[45px] transform -translate-x-1/2 w-10 h-10 bg-white rounded-full flex items-center justify-center">
                <img src={item.icon} alt={item.alt} className="w-6 h-6" />
              </div>
              <span className="text-[14px] leading-[20px] font-medium tracking-[-0.28px]">
                {item.text}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </section>
      </>  
    )

}



export default AboutUsPage;