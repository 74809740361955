import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import arrow from "../../images/arrow-red.png";

const HomeProductsSlider = (props) => {
  const { id, title, backgroundImage } = props;

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      const baseUrl = `https://${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wc/v3/products/`;
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      const queryString = new URLSearchParams({
        per_page: "4",
        status: "publish",
        order: "desc",
        orderby: "popularity",
        category: id,
        _fields: "id,slug,name,images",
        consumer_key: consumerKey,
        consumer_secret: consumerSecret,
      }).toString();

      try {
        const response = await fetch(`${baseUrl}?${queryString}`);
        if (!response.ok) {
          throw new Error("Products not found");
        }

        const products = await response.json();
        setProducts(products);
      } catch (error) {
        console.error("Failed to load products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [id]);

  return (
    <div className="bg-white bg-opacity-75">
      <div
        className="bg-cover bg-center h-60 relative"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container flex items-left h-full mx-auto">
          <h1 className="text-left text-white text-9xl font-bold absolute bottom-0">
            {title}
          </h1>
        </div>
      </div>
      <div className="container mx-auto p-4 bg-white">
        {isLoading ? (
          <p className="text-white text-center">Loading products...</p>
        ) : (
          <div className="flex justify-around items-center">
            {products.map((product, index) => (
              <Link key={index} to={`/product/${product.slug}`}>
                <div key={product.id} className="relative p-1 group">
                  <img
                    src={product.images[0]?.src}
                    alt={product.name}
                    loading="lazy"
                    className="h-60 w-60 object-contain"
                  />
                  <div className="absolute z-10 bottom-0 left-0 right-0 bg-red-600 bg-opacity-75 text-white text-center p-2 opacity-0 group-hover:opacity-100 transition-opacity">
                    {product.name}
                  </div>
                </div>
              </Link>
            ))}
            <div className="arrow p-1">
              <Link to={`/category/${id}`}>
                <img
                  src={arrow}
                  alt="arrow"
                  loading="lazy"
                  className="h-auto w-15 object-contain"
                />
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeProductsSlider;
